import * as React from 'react'
// import { forwardRef } from 'react'
// import { useEffect, useState } from 'react'
// import { Link } from 'gatsby'
import Logo from '../assets/tzkrk-logo.svg'
import Language from '../components/Language'

const Layout = ({ children, ...props }) => {
  // console.log('Settings2 =' + JSON.stringify(props.jezici))
  return (
    <div className='bg-brand h-full'>
      <header
        id='zaglavlje'
        className='flex items-center justify-center flex-wrap'>
        <Logo className={props.prikazaneGrupe ? 'w-20 ' : 'w-30 md:w-40 '} />
        <h1
          className={
            props.prikazaneGrupe
              ? 'text-xl md:text-2xl italic text-white font-playfair my-3 mx-4 center '
              : 'text-3xl md:text-5xl italic text-white font-playfair my-4 mx-6 center '
          }>
          {props.aktivniJezik.naslov}
        </h1>
        {!props.prikazaneGrupe && (
          <Language
            jezici={props.jezici}
            selectLanguage={props.selectLanguage}
            aktivniJezik={props.aktivniJezik}
          />
        )}
      </header>

      <main className='container mx-auto bg-bez my-8 p-0 pb-4'>{children}</main>
    </div>
  )
}
export default Layout
