// import React, { useState, useEffect, useRef } from 'react'
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Kategorije from '../components/Kategorije'
import IkonaKategorije from '../components/IkonaKategorije'
import Objekti from '../components/Objekti'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
// import { faTimes, faHome } from '@fortawesome/free-solid-svg-icons'

const Info = ({ data }) => {
  const SIRINA_NESTAJANJE = 2760
  const [listaKategorije, setKategorije] = useState([])
  const [listaKategorijeUGrupi, setKategorijeUGrupi] = useState([])
  const [subjekti, setSubjekti] = useState([])
  const [grupeHidden, hideGrupe] = useState(false)
  const [kategorijeHidden, hideKategorije] = useState(false)
  // const [grupa, setGrupa] = useState('')
  const [aktivnaGrupa, setAktivnaGrupa] = useState([])
  // const [grupaid, setGrupaId] = useState()
  const [subjektiUKategoriji, setSubjektiKategorija] = useState([])
  // const [kategorija, setKategorijaIme] = useState('')
  const [aktivnaKategorija, setAktivnaKategorija] = useState([])
  const [boja, setBoja] = useState('')
  const isBrowser = typeof window !== 'undefined'
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0)
  const [jezici, setJezici] = useState([])
  // const [jezik, setJezik] = useState('hr')
  const [aktivniJezik, setAktivniJezik] = useState([])
  // const refMenu = useRef(null)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  function handleOdabirKategorijeIzGrupe(grupa) {
    window.scrollTo(0, 0)
    // window.scrollTo(0, refMenu.current.offsetTop)
    // console.log('odabrao ID=' + grupa.grupaId)
    setSubjektiKategorija([])
    // setKategorijaIme('')
    // setAktivnaKategorija()
    setKategorijeUGrupi(kategorijeUGrupi(grupa))
    // setGrupa(grupa['grupa' + jezik])
    // setGrupaId(grupa.grupaId)
    // console.log('odabrao grupu ' + JSON.stringify(grupa))
    setAktivnaGrupa(grupa)
    setBoja(grupa.boja)
    hideGrupe(true)
    hideKategorije(false)
  }

  function kategorijeUGrupi(odabranaGrupa) {
    return listaKategorije.filter(
      (kate) => kate.grupaId === odabranaGrupa.grupaId
    )
  }

  function handlePrikaziGrupe() {
    hideGrupe(false)
    // window.scrollTo(0, 0)
  }
  // useEffect(() => {
  //   const element = refMenu.current
  //   console.log(element)
  //   console.log(element.id)
  // }, [])

  function selectKategorija(kate, e) {
    // console.log(refMenu.current.offsetTop)
    // window.scrollTo(0, refMenu.current.offsetTop)
    window.scrollTo(0, 0)
    setAktivnaKategorija(kate)
    hideKategorije(true)
    // console.log('odabrao kategoriju=' + e + ' ime ' + JSON.stringify(kate))
    setSubjektiKategorija(
      subjekti.filter((result) => result.kategorija.includes(e))
    )

    // console.log(
    //   'Aktivna kategorija=' +
    //     JSON.stringify(
    //       listaKategorije.filter((kategorija) => kategorija.kategorijaId === e)
    //     )
    // )

    // console.log('proizvodi u kategoriji=' + subjektiUKategoriji)
    // console.log(
    //   'proizvodi u kategoriji=' +
    //     subjekti.filter((result) => result.kategorija.includes(e))
    // )
    hideGrupe(true)
  }

  function selectLanguage(e) {
    // jezici.map((jez) => {
    //   jez === e ? (jez.aktivni = true) : (jez.aktivni = false)
    // })
    // console.log('Jezici=' + jez)

    // console.log('odabrao jezik=' + JSON.stringify(e))
    // console.log('Click target=' + e.language)
    // setJezik(e.languageCode)
    setAktivniJezik(e)
    // setSubjektiKategorija(
    //   subjekti.filter((result) => result.kategorija.includes(e))
    // )
    // setKategorijaIme(eime)
    // console.log('proizvodi u kategoriji=' + subjektiUKategoriji)
    // hideGrupe(true)
  }

  function handlePrikaziKategorije() {
    // window.scrollTo(0, refMenu.current.offsetTop)
    // console.log('handlePrikaziKategorije')
    hideKategorije(false)
    window.scrollTo(0, 0)
  }

  function getUnique(arr, comp) {
    const unique = arr
      //store the comparison values in array
      .map((e) => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])

      .map((e) => arr[e])

    return unique
  }

  useEffect(() => {
    const browserLanguage = getLanguage()
    // console.log('Jezik sadrzaja=' + browserLanguage)
    const subjektiList = data.allGoogleInfoSheet.edges.map((subjekt) => ({
      ...subjekt.node,
      ime: subjekt.node.name,
      kategorija: [
        subjekt.node.kategorija_0,
        subjekt.node.kategorija_1,
        subjekt.node.kategorija_2,
      ],
      // boja: product.node.boja,
    }))
    const kategorijeList = data.allGoogleKategorijeSheet.edges
      .map((kate) => ({
        ...kate.node,
        // kategorija: kategorija.node.kategorijahr,
        // kategorijaid: kategorija.node.kategorijaId,
        // grupa: kategorija.node.grupahr,
        // grupaid: kategorija.node.grupaId,
        // boja: kategorija.node.boja,
      }))
      // .sort((a, b) => (a.kategorijaId > b.kategorijaId ? 1 : -1))
      // .sort((a, b) => (a.grupaId > b.grupaId ? 1 : -1))
      .sort((a, b) => a.kategorijaId - b.kategorijaId)
      .sort((a, b) => a.grupaId - b.grupaId)

    console.log('Kategorije =' + JSON.stringify(kategorijeList))
    setKategorije(kategorijeList)
    // console.log('Kategorije =' + JSON.stringify(subjektiList))
    setSubjekti(subjektiList)
    const settings = data.allGoogleSetupSheet.edges.map((setting) => ({
      ...setting.node,
      languageCode: setting.node.languageCode,
      aktivni: setting.node.languageCode === browserLanguage ? true : false,
      // language: setting.node.language,
      // languageCode: setting.node.languageCode,
    }))
    // console.log('Settings =' + JSON.stringify(settings))
    setJezici(settings)
    setAktivniJezik(
      settings.find((jezik) => {
        return jezik.languageCode === browserLanguage
      })
    )
  }, [data])

  const uniqueGrupe = getUnique(listaKategorije, 'grupaId')
  const filterDropdown = subjekti.filter(
    (result) => result.kategorija === aktivnaGrupa.kategorijaId
  )

  const getLanguage = () => {
    // console.log('Browser language=' + navigator.language + ' / ' + navigator)
    if (typeof navigator === `undefined`) {
      return 'en'
    }

    const lang =
      navigator && navigator.language && navigator.language.split('-')[0]
    if (!lang) return 'en'

    if (['en', 'hr', 'it', 'sl', 'de'].includes(lang)) {
      return lang
    }

    return 'en'
  }

  return (
    <Layout
      jezici={jezici}
      selectLanguage={selectLanguage}
      aktivniJezik={aktivniJezik}
      prikazaneGrupe={grupeHidden}>
      <div
        // ref={refMenu}
        id='izbornik-bar'
        className={`izbornik-bar bg-${boja}`}>
        {grupeHidden && width < SIRINA_NESTAJANJE ? (
          <button
            onClick={() => handlePrikaziGrupe()}
            onKeyDown={() => handlePrikaziGrupe()}
            className={boja + ' buton-grupa p-2'}>
            {/* <IkonaKategorije ikona={aktivnaGrupa.grupaId} />
            {aktivnaGrupa['grupa' + aktivniJezik.languageCode]} */}
            <FontAwesomeIcon className='mr-2' icon={faHome} />
            Menu
          </button>
        ) : (
          <div className='odabir-grupa filters grid grid-cols-objavefit'>
            {uniqueGrupe.map((grupa, index) => (
              <button
                key={index}
                onClick={() =>
                  // handleOdabirKategorijeIzGrupe(grupa.grupa, grupa.boja)
                  handleOdabirKategorijeIzGrupe(grupa)
                }
                onKeyDown={() =>
                  // handleOdabirKategorijeIzGrupe(grupa.grupa, grupa.boja)
                  handleOdabirKategorijeIzGrupe(grupa)
                }
                value={grupa['grupa' + aktivniJezik.languageCode]}
                className={
                  'bg-' + grupa.boja + ' buton-grupa p-2 py-4 text-white'
                }>
                <IkonaKategorije ikona={grupa.grupaId} />
                {grupa['grupa' + aktivniJezik.languageCode]}
              </button>
            ))}
          </div>
        )}

        {!filterDropdown.length && (
          <>
            {kategorijeHidden && width < SIRINA_NESTAJANJE ? (
              <button
                onClick={() => handlePrikaziKategorije()}
                onKeyDown={() => handlePrikaziKategorije()}
                className={boja + ' buton-grupa p-2'}>
                <IkonaKategorije ikona={aktivnaGrupa.grupaId} />
                {aktivnaGrupa['grupa' + aktivniJezik.languageCode]}
                {/* <FontAwesomeIcon className='mr-2' icon={faTimes} />
                {aktivnaKategorija['kategorija' + aktivniJezik.languageCode]} */}
              </button>
            ) : (
              <>
                {/* // prikaz kategorija u odabranoj grupi */}
                <h2 className='text-3xl font-playfair italic text-white text-center mt-4 mb-6'>
                  {aktivnaGrupa['grupa' + aktivniJezik.languageCode]}
                </h2>
                <div className='grid grid-cols-objavefit gap-4'>
                  <Kategorije
                    // grupa={aktivnaGrupa.grupaId}
                    kategorije={listaKategorijeUGrupi}
                    boja={boja}
                    selectKategorija={selectKategorija}
                    jezik={aktivniJezik.languageCode}
                  />
                  {/* <subjektiSection subjekti={filterDropdown} /> */}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {!!subjektiUKategoriji.length && (
        <Objekti
          boja={boja}
          kategorija={
            aktivnaKategorija['kategorija' + aktivniJezik.languageCode]
          }
          proizvodiKategorija={subjektiUKategoriji}
          jezik={aktivniJezik.languageCode}
        />
      )}
    </Layout>
  )
}

export const data = graphql`
  {
    allGoogleInfoSheet(filter: { objava: { ne: "-" } }) {
      edges {
        node {
          id
          adresa_mjesto
          adresa_ulica
          adresa_zip
          boja
          contenthr
          contenten
          contentit
          contentde
          contentsl
          email_0
          email_1
          fax
          fb
          fburl
          firma
          grupa
          image
          imagealthr
          imagealten
          kategorija_0
          kategorija_1
          kategorija_2
          lan
          mob_0
          mob_1
          mob_2
          namehr
          nameen
          nameit
          namede
          namesl
          tel_0
          tel_1
          tel_2
          telfax
          webtitle
          weburl
          lat
          lon
        }
      }
    }
    allGoogleKategorijeSheet(filter: { usage: { gt: 0 } }) {
      edges {
        node {
          grupaId
          boja
          kategorijaId
          grupahr
          kategorijahr
          grupaen
          kategorijaen
          grupait
          kategorijait
          grupade
          kategorijade
          grupasl
          kategorijasl
        }
      }
    }

    allGoogleSetupSheet {
      edges {
        node {
          language
          id
          languageCode
          naslov
        }
      }
    }
  }
`

export default Info
